import * as React from 'react'

import Layout from '../layouts/Layout'
import { Link } from 'gatsby'
import BasicHero from '../components/Hero/BasicHero'
import { Container } from 'theme-ui'

const NotFound = () => {
  return (
    <Layout theme="dark">
      <BasicHero theme="dark" heading="404: Page Not Found" />
      <Container
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: {
            m: 0,
          },
        }}
      >
        <h1>404: Page Not Found</h1>
        <p>Keep on looking...</p>
        <p>Double check the URL or head back to the </p>
        <Link
          to={'/'}
          sx={{
            mt: 4,
            variant: 'buttons.primary',
          }}
        >
          Home
        </Link>
      </Container>
    </Layout>
  )
}

export default NotFound
